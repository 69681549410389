// Copyright (C) 2020-2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

import './styles.scss';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router';
import Spin from 'antd/lib/spin';

import { CombinedState, ProjectsQuery } from 'reducers/interfaces';
import { getProjectsAsync } from 'actions/projects-actions';
import FeedbackComponent from 'components/feedback/feedback';
import ExportDatasetModal from 'components/export-dataset/export-dataset-modal';
import EmptyListComponent from './empty-list';
import TopBarComponent from './top-bar';
import ProjectListComponent from './project-list';
import getCore from "../../cvat-core-wrapper";

const cvat = getCore();

export default function ProjectsPageComponent(): JSX.Element {
    const { search } = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const projectFetching = useSelector((state: CombinedState) => state.projects.fetching);
    const projectsCount = useSelector((state: CombinedState) => state.projects.current.length);
    const gettingQuery = useSelector((state: CombinedState) => state.projects.gettingQuery);
    const [cancreate, setCancreate] = useState(false);

    const anySearchQuery = !!Array.from(new URLSearchParams(search).keys()).filter((value) => value !== 'page').length;

    // check create rights
    useEffect(() => {
        async function fetchCanCreate() {
            let resp = await cvat.users.usercancreateprj();
            setCancreate(resp);
        }

        fetchCanCreate();
    }, []);

    /*
    useEffect(() => {
        async function fetchAPI(){
            let response = await fetch("/api/v1/users/userprjcreate");
            let data = await response.json();
            setCancreate(data["can_create"]);
        };
        fetchAPI();
    }, []);

     */

    useEffect(() => {
        const searchParams: Partial<ProjectsQuery> = {};
        for (const [param, value] of new URLSearchParams(search)) {
            searchParams[param] = ['page', 'id'].includes(param) ? Number.parseInt(value, 10) : value;
        }
        dispatch(getProjectsAsync(searchParams));
    }, []);

    useEffect(() => {
        const searchParams = new URLSearchParams();
        for (const [name, value] of Object.entries(gettingQuery)) {
            if (value !== null && typeof value !== 'undefined') {
                searchParams.append(name, value.toString());
            }
        }
        history.push({
            pathname: '/projects',
            search: `?${searchParams.toString()}`,
        });
    }, [gettingQuery]);

    if (projectFetching) {
        return <Spin size='large' className='cvat-spinner' />;
    }

    return (
        <div className='cvat-projects-page'>
            <TopBarComponent usergroup={"user"}
            cancreate={cancreate} />
            {projectsCount ? <ProjectListComponent /> : <EmptyListComponent notFound={anySearchQuery} />}
            <FeedbackComponent />
            <ExportDatasetModal />
        </div>
    );
}
