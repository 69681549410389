// Copyright (C) 2020-2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';

import Modal from 'antd/lib/modal';
import InputNumber from 'antd/lib/input-number';
import Text from 'antd/lib/typography/Text';
import { clamp } from 'utils/math';

interface Props {
    visible: boolean;
    setPasteShapeTransferAttributes(transfer: boolean): void;
    switchPasteShapeModalVisible(visible: boolean, responseSet: boolean): void;
    reset(): void;
    setPasteModalRef(component_ref: any): void;
    setPasteModalFetching(fetching: boolean): void;
}

export default function PasteShapeCopyAttributesConfirm(props: Props): JSX.Element {
    const {
        visible,
        switchPasteShapeModalVisible,
        reset,
        setPasteShapeTransferAttributes,
        setPasteModalFetching,
    } = props;

    return (
        <>
            <Modal
                okType='primary'
                okText='Yes'
                cancelText='No'
                onOk={() => {
                    setPasteShapeTransferAttributes(true);
                    switchPasteShapeModalVisible(false, true);
                }}
                onCancel={() => {
                    setPasteShapeTransferAttributes(false);
                    switchPasteShapeModalVisible(false, true);
                }}
                title='Transfer annotation attributes and ID?'
                visible={visible}
            >
                If you want to transfer also attributes and ID of annotation to be copied, click 'yes'.
            </Modal>
        </>
    );
}
