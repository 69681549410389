// Copyright (C) 2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';

import ObjectButtonsContainer from 'containers/annotation-page/standard-workspace/objects-side-bar/object-buttons';
import {ObjectType, ShapeType, ColorBy, HostEnv} from 'reducers/interfaces';
import ItemDetails, { attrValuesAreEqual } from './object-item-details';
import ItemBasics from './object-item-basics';
import consts from "../../../../consts";

interface Props {
    normalizedKeyMap: Record<string, string>;
    readonly: boolean;
    activated: boolean;
    objectType: ObjectType;
    shapeType: ShapeType;
    clientID: number;
    serverID: number | undefined;
    labelID: number;
    locked: boolean;
    attrValues: Record<number, string>;
    color: string;
    colorBy: ColorBy;
    labels: any[];
    attributes: any[];
    collapsed: boolean;
    jobInstance: any;
    activate(): void;
    copy(): void;
    propagate(): void;
    createURL(): void;
    switchOrientation(): void;
    toBackground(): void;
    toForeground(): void;
    remove(): void;
    changeLabel(label: any): void;
    changeAttribute(attrID: number, value: string): void;
    changeColor(color: string): void;
    collapse(): void;
    resetCuboidPerspective(): void;
    activateTracking(): void;
    shapeToTrack(): void;
}

function objectItemsAreEqual(prevProps: Props, nextProps: Props): boolean {
    return (
        nextProps.activated === prevProps.activated &&
        nextProps.readonly === prevProps.readonly &&
        nextProps.locked === prevProps.locked &&
        nextProps.labelID === prevProps.labelID &&
        nextProps.color === prevProps.color &&
        nextProps.clientID === prevProps.clientID &&
        nextProps.serverID === prevProps.serverID &&
        nextProps.objectType === prevProps.objectType &&
        nextProps.shapeType === prevProps.shapeType &&
        nextProps.collapsed === prevProps.collapsed &&
        nextProps.labels === prevProps.labels &&
        nextProps.attributes === prevProps.attributes &&
        nextProps.normalizedKeyMap === prevProps.normalizedKeyMap &&
        nextProps.colorBy === prevProps.colorBy &&
        attrValuesAreEqual(nextProps.attrValues, prevProps.attrValues)
    );
}

function ObjectItemComponent(props: Props): JSX.Element {
    const {
        activated,
        readonly,
        objectType,
        shapeType,
        clientID,
        serverID,
        locked,
        attrValues,
        labelID,
        color,
        colorBy,
        attributes,
        labels,
        collapsed,
        normalizedKeyMap,
        activate,
        copy,
        propagate,
        createURL,
        switchOrientation,
        toBackground,
        toForeground,
        remove,
        changeLabel,
        changeAttribute,
        changeColor,
        collapse,
        resetCuboidPerspective,
        activateTracking,
        shapeToTrack,
        jobInstance,
    } = props;

    const type =
        objectType === ObjectType.TAG ?
            ObjectType.TAG.toUpperCase() :
            `${shapeType.toUpperCase()} ${objectType.toUpperCase()}`;


    const clickDiv = consts.HOST_ENV === HostEnv.LOCAL;
    const hover_object_item = clickDiv ? 'cvat-objects-sidebar-state-item-hover' : ''

    const className = !activated ?
        `cvat-objects-sidebar-state-item ${hover_object_item}` :
        'cvat-objects-sidebar-state-item cvat-objects-sidebar-state-active-item';

    if (clickDiv) {
        return (
            <div style={{display: 'flex', marginBottom: '1px'}}>
                <div className='cvat-objects-sidebar-state-item-color' style={{background: `${color}`}}/>
                <div
                    onClick={activate}
                    id={`cvat-objects-sidebar-state-item-${clientID}`}
                    className={className}
                    style={{backgroundColor: activated ? `${color}90` : `${color}70`,
                            cursor: activated ? 'auto' : 'pointer'}}
                >
                    <ItemBasics
                        jobInstance={jobInstance}
                        readonly={readonly}
                        serverID={serverID}
                        clientID={clientID}
                        labelID={labelID}
                        labels={labels}
                        shapeType={shapeType}
                        objectType={objectType}
                        color={color}
                        colorBy={colorBy}
                        type={type}
                        locked={locked}
                        copyShortcut={normalizedKeyMap.COPY_SHAPE}
                        pasteShortcut={normalizedKeyMap.PASTE_SHAPE}
                        propagateShortcut={normalizedKeyMap.PROPAGATE_OBJECT}
                        shapeToTrackShortcut={normalizedKeyMap.SHAPE_TO_TRACK}
                        toBackgroundShortcut={normalizedKeyMap.TO_BACKGROUND}
                        toForegroundShortcut={normalizedKeyMap.TO_FOREGROUND}
                        removeShortcut={normalizedKeyMap.DELETE_OBJECT}
                        changeColorShortcut={normalizedKeyMap.CHANGE_OBJECT_COLOR}
                        changeLabel={changeLabel}
                        changeColor={changeColor}
                        copy={copy}
                        remove={remove}
                        propagate={propagate}
                        createURL={createURL}
                        switchOrientation={switchOrientation}
                        toBackground={toBackground}
                        toForeground={toForeground}
                        resetCuboidPerspective={resetCuboidPerspective}
                        activateTracking={activateTracking}
                        shapeToTrack={shapeToTrack}
                    />
                    <ObjectButtonsContainer readonly={!activated || readonly} clientID={clientID}/>
                    {!!attributes.length && (
                        <ItemDetails
                            readonly={readonly}
                            collapsed={collapsed}
                            attributes={attributes}
                            values={attrValues}
                            collapse={collapse}
                            changeAttribute={changeAttribute}
                        />
                    )}
                </div>
            </div>
        );
    } else {
        return (
            <div style={{display: 'flex', marginBottom: '1px'}}>
                <div className='cvat-objects-sidebar-state-item-color' style={{background: `${color}`}}/>
                <div
                    onMouseEnter={activate}
                    id={`cvat-objects-sidebar-state-item-${clientID}`}
                    className={className}
                    style={{backgroundColor: `${color}88`}}
                >
                    <ItemBasics
                        jobInstance={jobInstance}
                        readonly={readonly}
                        serverID={serverID}
                        clientID={clientID}
                        labelID={labelID}
                        labels={labels}
                        shapeType={shapeType}
                        objectType={objectType}
                        color={color}
                        colorBy={colorBy}
                        type={type}
                        locked={locked}
                        copyShortcut={normalizedKeyMap.COPY_SHAPE}
                        pasteShortcut={normalizedKeyMap.PASTE_SHAPE}
                        propagateShortcut={normalizedKeyMap.PROPAGATE_OBJECT}
                        shapeToTrackShortcut={normalizedKeyMap.SHAPE_TO_TRACK}
                        toBackgroundShortcut={normalizedKeyMap.TO_BACKGROUND}
                        toForegroundShortcut={normalizedKeyMap.TO_FOREGROUND}
                        removeShortcut={normalizedKeyMap.DELETE_OBJECT}
                        changeColorShortcut={normalizedKeyMap.CHANGE_OBJECT_COLOR}
                        changeLabel={changeLabel}
                        changeColor={changeColor}
                        copy={copy}
                        remove={remove}
                        propagate={propagate}
                        createURL={createURL}
                        switchOrientation={switchOrientation}
                        toBackground={toBackground}
                        toForeground={toForeground}
                        resetCuboidPerspective={resetCuboidPerspective}
                        activateTracking={activateTracking}
                        shapeToTrack={shapeToTrack}
                    />
                    <ObjectButtonsContainer readonly={readonly} clientID={clientID}/>
                    {!!attributes.length && (
                        <ItemDetails
                            readonly={readonly}
                            collapsed={collapsed}
                            attributes={attributes}
                            values={attrValues}
                            collapse={collapse}
                            changeAttribute={changeAttribute}
                        />
                    )}
                </div>
            </div>
        );
    }


}

export default React.memo(ObjectItemComponent, objectItemsAreEqual);
