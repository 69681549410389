// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import { connect } from 'react-redux';

import { CombinedState } from 'reducers/interfaces';
import CreatePatientsComponent from 'components/create-patients-page/create-patients-page';
import { CreatePatientsData } from 'components/create-patients-page/create-patients-content';
import { CreateTaskData } from 'components/create-task-page/create-task-content';
import { createTaskAsync } from 'actions/tasks-actions';

interface StateToProps {
    taskId: number | null;
    status: string;
    upload_progress: number | null;
    error: string;
    installedGit: boolean;
    croppingEnabled: boolean;
    croppingDefault: string | null;
}

interface DispatchToProps {
    onCreate: (data: CreateTaskData) => void;
}

function mapDispatchToProps(dispatch: any): DispatchToProps {
    return {
        onCreate: (data: CreateTaskData): void => dispatch(createTaskAsync(data)),
    };
}

function mapStateToProps(state: CombinedState): StateToProps {
    const { creates } = state.tasks.activities;
    const cropping = state.auth.enableCropping;
    const croppingDefault = state.auth.croppingDefault;

    return {
        ...creates,
        installedGit: state.plugins.list.GIT_INTEGRATION,
        croppingEnabled: cropping,
        croppingDefault: croppingDefault,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreatePatientsComponent);
