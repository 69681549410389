// Copyright (C) 2020-2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

import './styles.scss';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import Spin from 'antd/lib/spin';
import Button from 'antd/lib/button';
import message from 'antd/lib/message';
import Text from 'antd/lib/typography/Text';
import { Row, Col } from 'antd/lib/grid';
import { Table, List, Card, Progress } from 'antd';
import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';

import { TasksQuery } from 'reducers/interfaces';
import FeedbackComponent from 'components/feedback/feedback';
import TaskListContainer from 'containers/tasks-page/tasks-list';
import ExportDatasetModal from 'components/export-dataset/export-dataset-modal';
import TopBar from './top-bar';
import EmptyListComponent from './empty-list';
import ModelRunnerModal from "../model-runner-modal/model-runner-dialog";
import MoveTaskModal from "../move-task-modal/move-task-modal";

import { useHistory } from 'react-router';

// TODO use axios safe APIrequests
// import axios, {AxiosResponse} from 'axios';

interface UploadsPageProps {
    tasksFetching: boolean;
    gettingQuery: TasksQuery;
    numberOfTasks: number;
    numberOfVisibleTasks: number;
    numberOfHiddenTasks: number;
    taskImporting: boolean;
}

export default function UploadsPageComponent(): JSX.Element {
    const [uploadsFetching, setUploadsFetching] = useState(true);
    const [uploads, setUploads] = useState([]);
    const options_fetch = {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
        }
    };

    const history = useHistory();

    useEffect(() => {
        setUploadsFetching(true);
        async function fetchUploads() {
            fetch('/api/v1/uploads/grouped').then(res => res.json()).then((data) => {
                console.log(data);

                // unfold list objects
                // const d = [].concat.apply([], Object.values(data));
                Object.keys(data).forEach((key) => {
                    data[key].forEach((upload_file) => {
                        data[key]['parent_dir'] = upload_file.parent_dir;
                        data[key]['prj_id'] = upload_file.prj_id;
                        data[key]['name'] = upload_file.name;
                        upload_file['progress'] = Math.floor(upload_file.offset / upload_file.total_size * 100);
                        upload_file['offset'] = Math.max(upload_file['offset'] / 1000000, 0.001).toFixed(3);
                        upload_file['total_size'] = Math.max(upload_file['total_size'] / 1000000, 0.001).toFixed(3);
                    });
                });
                setUploads(data);
                setUploadsFetching(false);
                console.log(uploads);
            }).catch((err) => {
                throw err.message;
            })
        }
        fetchUploads();
    }, []);

    const columns = [
        {
            title: 'Filename',
            dataIndex: 'filename',
            key: 'filename',
            render: text => <a>{text}</a>,
        },
        {
            title: 'Offset [MBytes]',
            dataIndex: 'offset',
            key: 'offset',
        },
        {
            title: 'Upload progress',
            dataIndex: 'progress',
            key: 'progress',
            render: prog => <a>{(prog === 100) ? <Progress percent={prog} /> : <Progress percent={prog} showInfo={false} status="exception" />}</a>,
        },
    ];

    const getCardContent = (key) => {
        const upload = uploads[key];
        return (
            <Table pagination={false} columns={columns} dataSource={upload}/>
        );
    }

    if (uploadsFetching) {
        return <Spin size='large' className='cvat-spinner'/>;
    }

    return (
        <Row justify='center' align='top' className='cvat-uploads-page'>
            <Col md={22} lg={18} xl={16} xxl={14}>
                <TopBar/>
                <Row justify='center' align='middle'>
                    <Col className='cvat-uploads-list' md={22} lg={18} xl={16} xxl={14}>
                        <List
                            pagination={true}
                            dataSource={Object.keys(uploads)}
                            renderItem={item => (
                                <List.Item>
                                    {`Upload ${item}: ${uploads[item]['parent_dir']}`}
                                    <Button
                                        //className='cvat-header-button'
                                        type='link'
                                        value='uploads'
                                        href={`/patients/create?projectId=${uploads[item]['prj_id']}&name=${uploads[item]['name']}&expected_dir=${uploads[item]['parent_dir']}`}
                                        onClick={(event: React.MouseEvent): void => {
                                            const pathLink = `/patients/create?projectId=${uploads[item]['prj_id']}&name=${uploads[item]['name']}&expected_dir=${uploads[item]['parent_dir']}`;
                                            event.preventDefault();
                                            history.push(pathLink);
                                        }}
                                    >
                                        Resume
                                    </Button>
                                    {getCardContent(item)}
                                </List.Item>
                            )}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}
//
//
// class UploadsPageComponent extends React.PureComponent<UploadsPageProps & RouteComponentProps> {
//     public componentDidMount(): void {
//         const { gettingQuery, location, onGetTasks } = this.props;
//
//         const [uploads, setUploads] = useState([]);
//         const options_fetch = {
//             method: 'GET',
//             headers: {
//                 'Accept': 'application/json',
//                 'Content-Type': 'application/json;charset=UTF-8'
//             }
//         };
//
//         useEffect(() => {
//             // axios.get('/api/v1/uploads/grouped/').then((response) => {
//             //     console.log(response);
//             //     setUploads([1,2,3]);
//             //     console.log(uploads);
//             // })
//             async function fetchUploads() {
//                 fetch('/api/v1/uploads/grouped').then((response) => {
//                     console.log(response);
//                     setUploads([1, 2, 3]);
//                     console.log(uploads);
//                 }).catch((err) =>{
//                     throw err.message;
//                 })
//             }
//             fetchUploads();
//
//         }, []);
//     }
//
//     public componentDidUpdate(prevProps: UploadsPageProps & RouteComponentProps): void {
//         console.log('Uplodas component did update.');
//
//     }
//
//     public render(): JSX.Element {
//         // const {
//         //     tasksFetching, taskImporting,
//         // } = this.props;
//         //
//         // if (tasksFetching) {
//         //     return <Spin size='large' className='cvat-spinner' />;
//         // }
//
//         return (s
//             <div className='cvat-uploads-page'>
//                 Hello
//             </div>
//         );
//     }
// }

// export default withRouter(UploadsPageComponent);
