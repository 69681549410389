// Copyright (C) 2020-2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { Row, Col } from 'antd/lib/grid';
import Title from 'antd/lib/typography/Title';
import Text from 'antd/lib/typography/Text';

import getCore from 'cvat-core-wrapper';
import { Project } from 'reducers/interfaces';
import { updateProjectAsync } from 'actions/projects-actions';
import LabelsEditor from 'components/labels-editor/labels-editor';
import BugTrackerEditor from 'components/task-page/bug-tracker-editor';
import UserSelector from 'components/task-page/user-selector';
import MultiUserSelector from 'components/task-page/user-selector-dropdown'

const core = getCore();

interface DetailsComponentProps {
    project: Project;
}


export default function DetailsComponent(props: DetailsComponentProps): JSX.Element {
    const { project } = props;

    const dispatch = useDispatch();
    const [projectName, setProjectName] = useState(project.name);
    const [projectAssignees, setProjectAssignees] = useState(project.assignees);
    const [cancreate, setCancreate] = useState(false);

    // check create rights
    useEffect(() => {
        async function fetchCanCreate() {
            let resp = await core.users.usercancreateprj();
            setCancreate(resp);
        }

        fetchCanCreate();
    }, []);

    return (
        <div cvat-project-id={project.id} className='cvat-project-details'>
            <Row>
                <Col>
                    <Title
                        level={4}
                        // editable only if user can create projects otherwise false
                        editable={(cancreate) ? ({
                            onChange: (value: string): void => {
                                setProjectName(value);
                                project.name = value;
                                dispatch(updateProjectAsync(project));
                            },
                        }) : (false)}
                        className='cvat-text-color cvat-project-name'
                    >
                        {projectName}
                    </Title>
                </Col>
            </Row>
            {cancreate && (
                <Row justify='space-between' className='cvat-project-description'>
                    <Col>
                        <Text type='secondary'>
                            {`Project #${project.id} created`}
                            {project.owner ? ` by ${project.owner.username}` : null}
                            {` on ${moment(project.createdDate).format('MMMM Do YYYY')}`}
                        </Text>
                        <BugTrackerEditor
                            instance={project}
                            onChange={(bugTracker): void => {
                                project.bugTracker = bugTracker;
                                dispatch(updateProjectAsync(project));
                            }}
                        />
                    </Col>
                    <Col>
                        <Text type='secondary'>Assigned to</Text>
                        <MultiUserSelector
                            values_set={projectAssignees}
                            onSelect={(assignees_str_ids): void => {
                                const assignees_int = assignees_str_ids.map(assignee_id_str => parseInt(assignee_id_str));
                                setProjectAssignees(assignees_int);
                                project.assignees = assignees_int;
                                dispatch(updateProjectAsync(project));
                            }}
                        />
                    </Col>
                </Row>
            )}
            {cancreate && (
                <LabelsEditor
                    labels={project.labels.map((label: any): string => label.toJSON())}
                    onSubmit={(labels: any[]): void => {
                        project.labels = labels.map((labelData): any => new core.classes.Label(labelData));
                        dispatch(updateProjectAsync(project));
                    }}
                />
            )}
        </div>
    );
}
