// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React, { RefObject } from 'react';
import Input from 'antd/lib/input';
import Form, { FormInstance } from 'antd/lib/form';
import { Store } from 'antd/lib/form/interface';

export interface BaseConfiguration {
    name: string;
}

interface Props {
    onSubmit(values: BaseConfiguration): void;
}

export default class BasicConfigurationForm extends React.PureComponent<Props> {
    private formRef: RefObject<FormInstance>;
    private disabled: boolean;

    private getInitialName(): string {
        const date = new Date().toISOString().split('T', 1)[0];
        return date.replaceAll('-','');
    }

    private initialValues: BaseConfiguration = {
        name: this.getInitialName(),
    };

    public constructor(props: Props) {
        super(props);

        this.formRef = React.createRef<FormInstance>();
        this.disabled = false;
    }

    public submit(): Promise<void> {
        const { onSubmit } = this.props;
        if (this.formRef.current) {
            return this.formRef.current.validateFields().then((values: Store): Promise<void> => {
                onSubmit({ name: values.name });
                return Promise.resolve();
            });
        }

        return Promise.reject(new Error('Form ref is empty'));
    }

    public setVal( val ): void {
        if (this.formRef.current) {
            this.formRef.current.setFieldsValue({'name': val});
        }
    }

    public setDisabled( val ): void {
        this.disabled = val;
    }

    public resetFields(): void {
        if (this.formRef.current) {
            this.formRef.current.resetFields();
        }
    }

    public render(): JSX.Element {
        return (
            <Form initialValues={this.initialValues} ref={this.formRef} layout='vertical'>
                <Form.Item
                    hasFeedback
                    name='name'
                    label={<span>Name/Prefix</span>}
                    rules={[
                        {
                            required: true,
                            message: 'Patient data prefix cannot be empty.',
                        },
                    ]}
                >
                    <Input disabled={this.disabled} />
                </Form.Item>
            </Form>
        );
    }
}


// <Input disabled={true} placeholder="Filled automatically for MediCapture-data" />
