// Copyright (C) 2020-2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { Col } from 'antd/lib/grid';
import Icon from '@ant-design/icons';
import Popover from 'antd/lib/popover';

import CVATTooltip from 'components/common/cvat-tooltip';

import {
    FirstIcon,
    BackJumpIcon,
    PreviousIcon,
    PreviousFilteredIcon,
    PreviousEmptyIcon,
    PreviousChapterIcon,
    PlayIcon,
    PauseIcon,
    NextIcon,
    NextFilteredIcon,
    NextEmptyIcon,
    NextChapterIcon,
    ForwardJumpIcon,
    LastIcon,
    PreviousAnnotatedIcon,
    NextAnnotatedIcon,
} from 'icons';
import {HostEnv} from "../../../reducers/interfaces";
import consts from "../../../consts";

interface Props {
    playing: boolean;
    playPauseShortcut: string;
    nextFrameShortcut: string;
    previousFrameShortcut: string;
    forwardShortcut: string;
    backwardShortcut: string;
    prevButtonType: string;
    nextButtonType: string;
    nextAnnotFrameShortcut: string;
    previousAnnotFrameShortcut: string;
    onSwitchPlay(): void;
    onPrevFrame(): void;
    onNextFrame(): void;
    onPrevChapterFrame(): void;
    onNextChapterFrame(): void;
    onNextAnnotatedFrame(): void;
    onPrevAnnotatedFrame(): void;
    onForward(): void;
    onBackward(): void;
    onFirstFrame(): void;
    onLastFrame(): void;
    setPrevButton(type: 'regular' | 'filtered' | 'empty' | 'chapter'): void;
    setNextButton(type: 'regular' | 'filtered' | 'empty' | 'chapter'): void;
}

function PlayerButtons(props: Props): JSX.Element {
    const {
        playing,
        playPauseShortcut,
        nextFrameShortcut,
        previousFrameShortcut,
        forwardShortcut,
        backwardShortcut,
        prevButtonType,
        nextButtonType,
        nextAnnotFrameShortcut,
        previousAnnotFrameShortcut,
        onSwitchPlay,
        onPrevFrame,
        onNextFrame,
        onPrevChapterFrame,
        onNextChapterFrame,
        onPrevAnnotatedFrame,
        onNextAnnotatedFrame,
        onForward,
        onBackward,
        onFirstFrame,
        onLastFrame,
        setPrevButton,
        setNextButton,
    } = props;

    const chapterButtonMainNav = (consts.HOST_ENV === HostEnv.WEB);
    const annotatedFrameButtonMainNav = true;

    const prevRegularText = 'Go back';
    const prevFilteredText = 'Go back with a filter';
    const prevEmptyText = 'Go back to an empty frame';
    const prevChapterText = 'Go back to previous chapter frame';
    const prevAnnotatedText = 'Go back to previous annotated frame';
    const nextRegularText = 'Go next';
    const nextFilteredText = 'Go next with a filter';
    const nextEmptyText = 'Go next to an empty frame';
    const nextChapterText = 'Go to next chapter frame';
    const nextAnnotatedText = 'Go to next annotated frame';

    let prevButton = <Icon className='cvat-player-previous-button' component={PreviousIcon} onClick={onPrevFrame} />;
    let prevButtonTooltipMessage = prevRegularText;
    if (prevButtonType === 'filtered') {
        prevButton = (
            <Icon
                className='cvat-player-previous-button-filtered'
                component={PreviousFilteredIcon}
                onClick={onPrevFrame}
            />
        );
        prevButtonTooltipMessage = prevFilteredText;
    } else if (prevButtonType === 'empty') {
        prevButton = (
            <Icon className='cvat-player-previous-button-empty' component={PreviousEmptyIcon} onClick={onPrevFrame} />
        );
        prevButtonTooltipMessage = prevEmptyText;
    } else if (prevButtonType === 'chapter') {
        prevButton = (
            <Icon className='cvat-player-previous-button-chapter' component={PreviousChapterIcon} onClick={onPrevFrame} />
        );
        prevButtonTooltipMessage = prevChapterText;
    }


    let nextButton = <Icon className='cvat-player-next-button' component={NextIcon} onClick={onNextFrame} />;
    let nextButtonTooltipMessage = nextRegularText;
    if (nextButtonType === 'filtered') {
        nextButton = (
            <Icon className='cvat-player-next-button-filtered' component={NextFilteredIcon} onClick={onNextFrame} />
        );
        nextButtonTooltipMessage = nextFilteredText;
    } else if (nextButtonType === 'empty') {
        nextButton = <Icon className='cvat-player-next-button-empty' component={NextEmptyIcon} onClick={onNextFrame} />;
        nextButtonTooltipMessage = nextEmptyText;
    } else if (nextButtonType === 'chapter') {
        nextButton = <Icon className='cvat-player-next-button-chapter' component={NextChapterIcon} onClick={onNextFrame} />;
        nextButtonTooltipMessage = nextChapterText;
    }

    return (
        <Col className='cvat-player-buttons'>
            {!chapterButtonMainNav && <CVATTooltip title='Go to the first frame'>
                <Icon className='cvat-player-first-button' component={FirstIcon} onClick={onFirstFrame} />
            </CVATTooltip>}
            <CVATTooltip title={`Go back with a step ${backwardShortcut}`}>
                <Icon className='cvat-player-backward-button' component={BackJumpIcon} onClick={onBackward} />
            </CVATTooltip>
            {annotatedFrameButtonMainNav && <CVATTooltip title={`${prevAnnotatedText} ${previousAnnotFrameShortcut}`}>
                <Icon className='cvat-player-backward-annot-button' component={PreviousAnnotatedIcon} onClick={onPrevAnnotatedFrame} />
            </CVATTooltip>}
            {chapterButtonMainNav &&
            <CVATTooltip title={`${prevChapterText}`}>
                <Icon
                    className='cvat-player-previous-chapter-inlined-button'
                    component={PreviousChapterIcon}
                    onClick={onPrevChapterFrame}
                />
            </CVATTooltip>
            }
            <Popover
                trigger='contextMenu'
                placement='bottom'
                content={(
                    <>
                        <CVATTooltip title={`${prevRegularText}`}>
                            <Icon
                                className='cvat-player-previous-inlined-button'
                                component={PreviousIcon}
                                onClick={() => {
                                    setPrevButton('regular');
                                }}
                            />
                        </CVATTooltip>
                        <CVATTooltip title={`${prevFilteredText}`}>
                            <Icon
                                className='cvat-player-previous-filtered-inlined-button'
                                component={PreviousFilteredIcon}
                                onClick={() => {
                                    setPrevButton('filtered');
                                }}
                            />
                        </CVATTooltip>
                        <CVATTooltip title={`${prevEmptyText}`}>
                            <Icon
                                className='cvat-player-previous-empty-inlined-button'
                                component={PreviousEmptyIcon}
                                onClick={() => {
                                    setPrevButton('empty');
                                }}
                            />
                        </CVATTooltip>
                        {!chapterButtonMainNav &&
                            <CVATTooltip title={`${prevChapterText}`}>
                                <Icon
                                    className='cvat-player-previous-chapter-inlined-button'
                                    component={PreviousChapterIcon}
                                    onClick={() => {
                                        setPrevButton('chapter');
                                    }}
                                />
                            </CVATTooltip>
                        }
                    </>
                )}
            >
                <CVATTooltip placement='top' title={`${prevButtonTooltipMessage} ${previousFrameShortcut}`}>
                    {prevButton}
                </CVATTooltip>
            </Popover>

            {!playing ? (
                <CVATTooltip title={`Play ${playPauseShortcut}`}>
                    <Icon className='cvat-player-play-button' component={PlayIcon} onClick={onSwitchPlay} />
                </CVATTooltip>
            ) : (
                <CVATTooltip title={`Pause ${playPauseShortcut}`}>
                    <Icon className='cvat-player-pause-button' component={PauseIcon} onClick={onSwitchPlay} />
                </CVATTooltip>
            )}

            <Popover
                trigger='contextMenu'
                placement='bottom'
                content={(
                    <>
                        <CVATTooltip title={`${nextRegularText}`}>
                            <Icon
                                className='cvat-player-next-inlined-button'
                                component={NextIcon}
                                onClick={() => {
                                    setNextButton('regular');
                                }}
                            />
                        </CVATTooltip>
                        <CVATTooltip title={`${nextFilteredText}`}>
                            <Icon
                                className='cvat-player-next-filtered-inlined-button'
                                component={NextFilteredIcon}
                                onClick={() => {
                                    setNextButton('filtered');
                                }}
                            />
                        </CVATTooltip>
                        <CVATTooltip title={`${nextEmptyText}`}>
                            <Icon
                                className='cvat-player-next-empty-inlined-button'
                                component={NextEmptyIcon}
                                onClick={() => {
                                    setNextButton('empty');
                                }}
                            />
                        </CVATTooltip>
                        {!chapterButtonMainNav && <CVATTooltip title={`${nextChapterText}`}>
                            <Icon
                                className='cvat-player-next-chapter-inlined-button'
                                component={NextChapterIcon}
                                onClick={() => {
                                    setNextButton('chapter');
                                }}
                            />
                        </CVATTooltip>
                        }
                    </>
                )}
            >
                <CVATTooltip placement='top' title={`${nextButtonTooltipMessage} ${nextFrameShortcut}`}>
                    {nextButton}
                </CVATTooltip>
            </Popover>
            {chapterButtonMainNav && <CVATTooltip title={`${nextChapterText}`}>
                <Icon
                    className='cvat-player-next-chapter-inlined-button'
                    component={NextChapterIcon}
                    onClick={onNextChapterFrame}
                />
            </CVATTooltip>
            }
            {annotatedFrameButtonMainNav && <CVATTooltip title={`${nextAnnotatedText} ${nextAnnotFrameShortcut}`}>
                <Icon className='cvat-player-forward-annot-button' component={NextAnnotatedIcon} onClick={onNextAnnotatedFrame} />
            </CVATTooltip>}
            <CVATTooltip title={`Go next with a step ${forwardShortcut}`}>
                <Icon className='cvat-player-forward-button' component={ForwardJumpIcon} onClick={onForward} />
            </CVATTooltip>
            {!chapterButtonMainNav &&
            <CVATTooltip title='Go to the last frame'>
                <Icon className='cvat-player-last-button' component={LastIcon} onClick={onLastFrame}/>
            </CVATTooltip>
            }
        </Col>
    );
}

export default React.memo(PlayerButtons);
