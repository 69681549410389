// Copyright (C) 2020-2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React, { useState, useEffect, useRef } from 'react';
import { SelectValue, RefSelectProps } from 'antd/lib/select';
import Autocomplete from 'antd/lib/auto-complete';
import Input from 'antd/lib/input';
import debounce from 'lodash/debounce';

import getCore from 'cvat-core-wrapper';

const core = getCore();

export interface User {
    id: number;
    username: string;
}

interface Props {
    values_set: [] | null;
    className?: string;
    onSelect: ([]) => void;
}

import 'antd/dist/antd.css';
import { Select } from 'antd';

const { Option } = Select;


const searchUsers = debounce(
    (searchValue: string, setUsers: (users: User[]) => void): void => {
        core.users
            .get({
                search: searchValue,
                limit: 10,
                is_active: true,
            })
            .then((result: User[]) => {
                if (result) {
                    setUsers(result);
                }
            });
    },
    250,
    {
        maxWait: 750,
    },
);

function getOptions() {

    const [users, setUsers] = useState<User[]>([]);

    useEffect(() => {
    core.users.get({ limit: 1000, is_active: true }).then((result: User[]) => {
        if (result) {
            setUsers(result);
        }
    });
    }, []);

    const user_options = [];
    users.map((user) => user_options.push(<Option key={user.id.toString()}>{user.username}</Option>));

    return (user_options);
}


export default function MultiUserSelector(props: Props): JSX.Element {

    let { values_set, className, onSelect } = props;
    const [searchPhrase, setSearchPhrase] = useState('');
    const [initialUsers, setInitialUsers] = useState<User[]>([]);
    const [defaultUsers, setDefaultUsers] = useState([]);

    useEffect(() => {
        console.log('use effect setdefaultusers');
        setDefaultUsers(values_set);
    }, [values_set]);

    console.log("MULTI USER value")
    console.log(values_set)

    /*
    const [users, setUsers] = useState<User[]>([]);

    useEffect(() => {
    core.users.get({ limit: 1000, is_active: true }).then((result: User[]) => {
        if (result) {
            setInitialUsers(result);
        }
    });
    }, []);

    useEffect(() => {
        setUsers(initialUsers);
    }, [initialUsers]);

    useEffect(() => {
        if (searchPhrase) {
            searchUsers(searchPhrase, setUsers);
        } else {
            setUsers(initialUsers);
        }
    }, [searchPhrase]);

     */

    let user_opt = getOptions();

    console.log(user_opt);

    const handleSelect = (_value: []): void => {
        console.log('This is handle select.');
        console.log('values_set')
        console.log(values_set)
        console.log('_value')
        console.log(_value);
        values_set = _value
        onSelect(_value);
        console.log('values_set')
        console.log(values_set)
    };

    return (
        <div>
            <Select
              mode="multiple"
              allowClear
              style={{ width: '100%' }}
              placeholder="Please select"
              defaultValue={values_set.map(value => value.toString())}
              onChange={handleSelect}
            >
              {user_opt}
            </Select>
        </div>
    );
}
