// Copyright (C) 2019-2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

import './styles.scss';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { Row, Col } from 'antd/lib/grid';
import Modal from 'antd/lib/modal';
import Text from 'antd/lib/typography/Text';
import Paragraph from 'antd/lib/typography/Paragraph';
import TextArea from 'antd/lib/input/TextArea';

import CreatePatientsContent, { CreatePatientsData } from './create-patients-content';

interface Props {
    onCreate: (data: CreatePatientsData) => void;
    status: string;
    upload_progress: number | null;
    error: string;
    taskId: number | null;
    installedGit: boolean;
    croppingEnabled: boolean | null;
    croppingDefault: string | null;
}

export default function CreatePatientsPage(props: Props): JSX.Element {
    const {
        error, status, upload_progress, taskId, onCreate, installedGit, croppingEnabled, croppingDefault,
    } = props;

    const location = useLocation();

    let projectId = null;
    let task_name_prefix = null;
    let expected_directory = null;
    const params = new URLSearchParams(location.search);
    if (params.get('projectId')?.match(/^[1-9]+[0-9]*$/)) {
        projectId = +(params.get('projectId') as string);
    }

    if (params.get('name')){
        task_name_prefix = params.get('name');
    }

    if (params.get('expected_dir')){
        expected_directory = params.get('expected_dir');
    }

    useEffect(() => {
        if (error) {
            let errorCopy = error;
            const sshKeys: string[] = [];
            while (errorCopy.length) {
                const startIndex = errorCopy.search(/'ssh/);
                if (startIndex === -1) break;
                let sshKey = errorCopy.slice(startIndex + 1);
                const stopIndex = sshKey.search(/'/);
                sshKey = sshKey.slice(0, stopIndex);
                sshKeys.push(sshKey);
                errorCopy = errorCopy.slice(stopIndex + 1);
            }

            if (sshKeys.length) {
                Modal.error({
                    width: 800,
                    title: 'Could not clone the repository',
                    className: 'cvat-create-patients-clone-repository-fail',
                    content: (
                        <>
                            <Paragraph>
                                <Text>Please make sure it exists and you have access</Text>
                            </Paragraph>
                            <Paragraph>
                                <Text>Consider adding the following public ssh keys to git: </Text>
                            </Paragraph>
                            <TextArea rows={10} value={sshKeys.join('\n\n')} />
                        </>
                    ),
                });
            }
        }
    }, [error]);

    return (
        <Row justify='center' align='top' className='cvat-create-patients-form-wrapper'>
            <Col md={20} lg={16} xl={14} xxl={9}>
                <Text className='cvat-title'>Upload new patient data</Text>
                <CreatePatientsContent
                    taskId={taskId}
                    projectId={projectId}
                    taskName={task_name_prefix}
                    expectedDir={expected_directory}
                    status={status}
                    upload_progress={upload_progress}
                    onCreate={onCreate}
                    installedGit={installedGit}
                    croppingEnabled={croppingEnabled}
                    croppingDefault={croppingDefault}
                />
            </Col>
        </Row>
    );
}
