// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React, { RefObject } from 'react';
import Input from 'antd/lib/input';
import Form, { FormInstance } from 'antd/lib/form';
import {Button, Radio, InputNumber, DatePicker, Select} from 'antd';
import { Row, Col } from 'antd/lib/grid';
import { Store } from 'antd/lib/form/interface';

export interface CroppingConfiguration {
    croppingOption: string;
}

interface Props {
    onSubmit(values: CroppingConfiguration): void;
    handleChange(value): void;
    options: [];
    disabled: boolean;
}

export default class CroppingConfigurationForm extends React.PureComponent<Props> {
    private formRef: RefObject<FormInstance>;
    private disabled: boolean;

    private initialValues: CroppingConfiguration = {
        croppingOption: 'No cropping',
    };

    public constructor(props: Props) {
        super(props);

        this.formRef = React.createRef<FormInstance>();
        this.disabled = false;
    }

    private getRadioOptions(): any[] {
        const options = [];

        const opt_dict = ['No cropping', 'EPK-i7010', 'EPK-i', 'EPK-i5500c'];

        opt_dict.map(
            (key) => options.push(
                <Radio value={key}>{key}</Radio>
            )
        );

        return (options);
    }

    public submit(): Promise<void> {
        const { onSubmit } = this.props;
        if (this.formRef.current) {
            return this.formRef.current.validateFields().then((values: Store): Promise<void> => {
                console.log(values);
                onSubmit(values.croppingOption);
                return Promise.resolve();
            });
        }

        return Promise.reject(new Error('Form ref is empty'));
    }

    public setVal( val ): void {
        if (this.formRef.current) {
            this.formRef.current.setFieldsValue({'croppingOption': val});
        }
    }

    public setDisabled( val ): void {
        this.disabled = val;
    }

    public resetFields(): void {
        if (this.formRef.current) {
            this.formRef.current.resetFields();
        }
    }

    private renderCroppingProcessors(): JSX.Element {
        const { handleChange, options, disabled} = this.props;

        // retain possible options
        // const options = this.getRadioOptions();

        const handleChangeInt = e => {
            console.log('radio checked processor ', e.target.value);
            // setValuee(e.target.value);
            // onChange(e.target.value);
        };

        return (
            <Form.Item
                help="Set cropping options for anonymizing media to be uploaded."
                name="croppingOption"
            >
                <Select onChange={handleChange} disabled={disabled}>
                    {options}
                </Select>
            </Form.Item>
        );
    }

    public render(): JSX.Element {
        const { onSubmit, onReset} = this.props;

        return (
            <Form initialValues={this.initialValues} ref={this.formRef} layout='vertical'
                  onFinish={onSubmit} preserve={true}>
                <Row>
                    <Col span={24}>{this.renderCroppingProcessors()}</Col>
                </Row>
            </Form>);
    }
}
