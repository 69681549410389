// Copyright (C) 2019-2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

const HOST_ENV = 'web'
const UNDEFINED_ATTRIBUTE_VALUE = '__undefined__';
const NO_BREAK_SPACE = '\u00a0';
const CHANGELOG_URL = 'https://github.com/openvinotoolkit/cvat/blob/develop/CHANGELOG.md';
const LICENSE_URL = 'https://github.com/openvinotoolkit/cvat/blob/develop/LICENSE';
const GITTER_URL = 'https://gitter.im/opencv-cvat';
const GITTER_PUBLIC_URL = 'https://gitter.im/opencv-cvat/public';
const FORUM_URL = 'https://software.intel.com/en-us/forums/intel-distribution-of-openvino-toolkit';
const GITHUB_URL = 'https://github.com/openvinotoolkit/cvat';
const GITHUB_IMAGE_URL =
    'https://github.com/openvinotoolkit/cvat/raw/develop/site/content/en/images/cvat.jpg';
const SHARE_MOUNT_GUIDE_URL =
    'https://openvinotoolkit.github.io/cvat/docs/administration/basics/installation/#share-path';
const NUCLIO_GUIDE =
    'https://openvinotoolkit.github.io/cvat//docs/administration/advanced/installation_automatic_annotation/';
const CANVAS_BACKGROUND_COLORS = ['#ffffff', '#f1f1f1', '#e5e5e5', '#d8d8d8', '#CCCCCC', '#B3B3B3', '#999999'];
const NEW_LABEL_COLOR = '#b3b3b3';
const LATEST_COMMENTS_SHOWN_QUICK_ISSUE = 3;
const QUICK_ISSUE_INCORRECT_POSITION_TEXT = 'Wrong position';
const QUICK_ISSUE_INCORRECT_ATTRIBUTE_TEXT = 'Wrong attribute';
const DEFAULT_PROJECT_SUBSETS = ['Train', 'Test', 'Validation'];
const DEFAULT_GENDER_OPTIONS = {M: 'Male', F: 'Female', O: 'Other', N: 'Not available'};
const DEFAULT_ETHNICITY_OPTIONS = {A: 'Asian', B: 'Black', W: 'White', O: 'Other', N: 'Not available'};
const DEFAULT_ENDOSCOPE_OPTIONS = {
    EC38i10cM: 'EC38-i10cM',
    EC38i10cF: 'EC38-i10cF',
    EC38i10cF2: 'EC38-i10cF2',
    EC38i10cL: 'EC38-i10cL',
    EC38i20c: 'EC38-i20c',
    EC38i10M: 'EC38-i10M',
    EC38i10M2: 'EC38-i10M2',
    EC38i10F: 'EC38-i10F',
    EC38i10F2: 'EC38-i10F2',
    EC38i10L: 'EC38-i10L',
    EC34i10c: 'EC34-i10c',
    EC34i10cM: 'EC34-i10cM',
    EC34i10cF: 'EC34-i10cF',
    EC34i10cL: 'EC34-i10cL',
    EG29i10: 'EG29-i10',
    EG29i10c: 'EG29-i10c',
    EG29i20c: 'EG29-i20c',
    EC38i20cWM: 'EC38-i20cWM',
    EC38i20cWL: 'EC38-i20cWL',
    EC38i20cWF: 'EC38-i20cWF',
    O: 'Other', N: 'Not available'};
const DEFAULT_PROCESSOR_OPTIONS = {EPKi: 'EPK-i', EPKi7010: 'EPK-i7010', EPKi5500c: 'EPK-i5500c',
    EPKi8020c: 'EPK-i8020c', O: 'Other', N: 'Not available'};
const INTEL_TERMS_OF_USE_URL = 'https://www.intel.com/content/www/us/en/legal/terms-of-use.html';
const INTEL_COOKIES_URL = 'https://www.intel.com/content/www/us/en/privacy/intel-cookie-notice.html';
const INTEL_PRIVACY_URL = 'https://www.intel.com/content/www/us/en/privacy/intel-privacy-notice.html';

export default {
    UNDEFINED_ATTRIBUTE_VALUE,
    NO_BREAK_SPACE,
    CHANGELOG_URL,
    LICENSE_URL,
    GITTER_URL,
    GITTER_PUBLIC_URL,
    FORUM_URL,
    GITHUB_URL,
    GITHUB_IMAGE_URL,
    SHARE_MOUNT_GUIDE_URL,
    CANVAS_BACKGROUND_COLORS,
    NEW_LABEL_COLOR,
    NUCLIO_GUIDE,
    LATEST_COMMENTS_SHOWN_QUICK_ISSUE,
    QUICK_ISSUE_INCORRECT_POSITION_TEXT,
    QUICK_ISSUE_INCORRECT_ATTRIBUTE_TEXT,
    DEFAULT_PROJECT_SUBSETS,
    DEFAULT_GENDER_OPTIONS,
    DEFAULT_ETHNICITY_OPTIONS,
    DEFAULT_ENDOSCOPE_OPTIONS,
    DEFAULT_PROCESSOR_OPTIONS,
    INTEL_TERMS_OF_USE_URL,
    INTEL_COOKIES_URL,
    INTEL_PRIVACY_URL,
    HOST_ENV,
};
