// Copyright (C) 2019-2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React, { useEffect, useState } from 'react';
import { Radio } from 'antd';

import consts from 'consts';
import getCore from 'cvat-core-wrapper';

const core = getCore();

interface Props {
    value: string;
    onChange: (value: string) => void;
}

function getOptions() {
    const options = [];

    Object.keys(consts.DEFAULT_GENDER_OPTIONS).map(
        (key) => options.push(
            <Radio value={key}>{consts.DEFAULT_GENDER_OPTIONS[key]}</Radio>
        )
    );

    return (options);
}

export default function ProjectGenderField(props: Props): JSX.Element {
    const {
        value, onChange,
    } = props;

    // retain possible options
    const options = getOptions();

    const [valuee, setValuee] = useState(value);

    const handleChange = e => {
        console.log('radio checked', e.target.value);
        setValuee(e.target.value);
        onChange(e.target.value);
    };

    return (
        <Radio.Group onChange={handleChange} value={valuee}>
            {options}
        </Radio.Group>
    );
}
