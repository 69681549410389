// Copyright (C) 2020-2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import {
    propagateObject as propagateObjectAction,
    changePropagateFrames as changePropagateFramesAction,
    propagateObjectAsync,
    switchPasteShapeModalVisible,
    resetPasteShapeModalVisible,
    setPasteShapeTransferAttributes,
    setPasteModalRef,
    setPasteModalFetching,
} from 'actions/annotation-actions';

import { CombinedState } from 'reducers/interfaces';
import PasteShapeCopyAttributesConfirm from 'components/annotation-page/standard-workspace/paste-shape-copy-attributes-confirm';

interface StateToProps {
    modalVisible: boolean;
    transferAttributes: boolean;
    modalFetching: boolean;
}

interface DispatchToProps {
    switchPasteShapeModalVisible(visible: boolean): void;
    reset(): void;
    setPasteShapeTransferAttributes(transfer_attributes: boolean): void;
    setPasteModalFetching(fetching: boolean): void;
}

function mapStateToProps(state: CombinedState): StateToProps {
    const {
        annotation: {
            pasteAnnotation: {
                pasteAnnotationModalVisible: modalVisible,
                transferAttributes: transferAttributes,
                modalFetching: modalFetching,
            },
        },
    } = state;

    return {
        modalVisible,
        transferAttributes,
        modalFetching,
    };
}

function mapDispatchToProps(dispatch: any): DispatchToProps {
    return {
        reset(): void {
            dispatch(resetPasteShapeModalVisible(null));
        },
        switchPasteShapeModalVisible(visible: boolean): void {
            dispatch(switchPasteShapeModalVisible(visible));
        },
        setPasteShapeTransferAttributes(transfer_attributes: boolean): void {
            dispatch(setPasteShapeTransferAttributes(transfer_attributes));
        },
        setPasteModalRef(component_ref: any): void {
            dispatch(setPasteModalRef(component_ref));
        },
        setPasteModalFetching(fetching: boolean): void {
            setPasteModalFetching(fetching);
        },
    };
}

type Props = StateToProps & DispatchToProps;
interface State {
    visible: boolean;
    responseSet: boolean;
}

class PasteShapeCopyAttributesConfirmContainer extends React.PureComponent<Props, State> {

    public constructor(props: Props) {
        super(props);
        this.state = {
            visible: false,
            responseSet: false,
        }
    }

    public componentDidMount(): void {
        const {setPasteModalRef} = this.props;
        // set reference for this copmponent for accessing and re-rendering it from actions
        console.log("Calling did mount function in PasteShapeCopyAttributesConfirmContainer.");
        setPasteModalRef(this);
    }

    public componentWillUnmount(): void {
        const {setPasteModalRef} = this.props;
        setPasteModalRef(undefined);
    }

    public componentDidUpdate(prevProps: Props, prevState: State): void {
        // this.setState({visible: false});
        setPasteModalRef(this);
    }

    private reset = (): void => {
        const {
            reset
        } = this.props;
        reset();
    };

    // public getResponse = (key: string): Promise<void> =>
    //     new Promise<void>((resolve, reject): void => {
    //
    //         this.setState({visible: true});
    //
    //         const [seconds, setSeconds] = useState(0);
    //
    //         useEffect(() => {
    //             const interval = setInterval(() => {
    //                 setSeconds(seconds => seconds + 1);
    //             }, 500);
    //             return () => clearInterval(interval);
    //         }, []);
    //
    //         const {onLoadData} = this.props;
    //
    //         const success = (): void => resolve();
    //         const failure = (): void => reject();
    //         onLoadData(key, success, failure);
    //     });

    private setPasteModalRef = (component_ref: any): void => {
        setPasteModalRef(component_ref);
    };

    private setPasteModalFetching = (fetching: boolean): void => {
        const { setPasteModalFetching } = this.props;
        setPasteModalFetching(fetching);
    };

    private switchPasteShapeModalVisible = (visible: boolean, responseSet:boolean): void => {
        const { switchPasteShapeModalVisible } = this.props;

        switchPasteShapeModalVisible(visible);
        this.setState({visible: visible, responseSet: responseSet});
        this.setPasteModalFetching(false);
    };

    private setPasteShapeTransferAttributes = (transfer_attributes: boolean): void => {
        const { setPasteShapeTransferAttributes } = this.props;
        setPasteShapeTransferAttributes(transfer_attributes);
    };

    public render(): JSX.Element {
        const {
            modalVisible,
        } = this.props;
        const { visible } = this.state;

        return (
            <PasteShapeCopyAttributesConfirm
                visible={visible}
                reset={this.reset}
                switchPasteShapeModalVisible={this.switchPasteShapeModalVisible}
                setPasteShapeTransferAttributes={this.setPasteShapeTransferAttributes}
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PasteShapeCopyAttributesConfirmContainer);
