// Copyright (C) 2019-2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React, { useEffect, useState } from 'react';
import { Input } from 'antd';

interface Props {
    value: string;
    onChange: (value: string) => void;
}

export default function ProjectPhysicianField(props: Props): JSX.Element {
    const {
        value, onChange,
    } = props;

    const handleChange = e => {
        console.log('radio projectphysicianfield', e.target.value);
        onChange(e.target.value);
    };

    return (
        <Input placeholder="Physician" defaultValue={value} onPressEnter={handleChange} />
    );
}
